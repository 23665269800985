import { Flex, Modal, Table, Tabs } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Tab2D from './components/Tab2D';
import Tab3D from './components/Tab3D';
import Tab3DChassis from './components/Tab3DChassis';

const { TabPane } = Tabs;

const grantMap = {
    labors: grants.OPERATIONS_ORDER_DOCUMENT_JOBS,
    storeGroups: grants.OPERATIONS_ORDER_DOCUMENT_PARTS
};

const VisualAdditionModal = ({
    open,
    resetModal,
    orderId,
    reloadOrderForm,
    user,
    isRetailOrder,
    defaultEmployeeId,
    intl: { formatMessage }
}) => {
    const [orderData, setOrderData] = useState();
    const [unitComment, setUnitComment] = useState();
    const [unitCommentPlaces, setUnitCommentPlaces] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState({ labors: [], storeGroups: [] });
    const [selectedRows, setSelectedRows] = useState({ labors: [], storeGroups: [] });
    const [toOrderData, setToOrderData] = useState({ labors: [], storeGroups: [] });

    const rowSelection = useCallback(
        type => ({
            selectedRowKeys: selectedRowKeys[type],
            onChange: (keys, rows) => {
                setSelectedRowKeys(prev => ({ ...prev, [type]: keys }));
                setSelectedRows(prev => ({ ...prev, [type]: rows }));
            },
            getCheckboxProps: () => ({
                disabled: !isGrantAccessed(user, grantMap[type], accesses.ROWO)
            })
        }),
        [selectedRowKeys, user]
    );

    const serviceColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='status_requests.LABOR' />,
                render: record => (
                    <Flex vertical>
                        <div>{record.laborName}</div>
                        <div style={{ fontWeight: 500, fontSize: 12 }}>{record.laborId}</div>
                    </Flex>
                )
            }
        ],
        []
    );
    const detailColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='add_order_form.details' />,
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName}</div>
                        <div style={{ fontWeight: 500, fontSize: 12 }}>{record.storeGroupId}</div>
                    </Flex>
                )
            }
        ],
        []
    );

    const handleCloseToOrderModal = useCallback(() => {
        setOrderData(undefined);
        setSelectedRowKeys({ labors: [], storeGroups: [] });
        setSelectedRows({ labors: [], storeGroups: [] });
    }, []);

    const handleConfirmAddModal = useCallback(() => {
        setToOrderData(prev => ({
            labors: !isRetailOrder
                ? [
                      ...prev.labors,
                      ...selectedRows.labors.map(record => ({
                          ...record,
                          laborName: unitComment ? `${record.laborName} - ${unitComment}` : `${record.laborName}`,
                          comment: unitComment
                              ? { comment: `${record.laborName} - ${unitComment}`, positions: unitCommentPlaces }
                              : {
                                    comment: undefined,
                                    positions: [],
                                    problems: []
                                }
                      }))
                  ]
                : [],
            storeGroups: [
                ...prev.storeGroups,
                ...selectedRows.storeGroups.map(record => ({
                    ...record,
                    storeGroupName: unitComment
                        ? `${record.storeGroupName} - ${unitComment}`
                        : `${record.storeGroupName}`,
                    comment: unitComment
                        ? { comment: `${record.storeGroupName} - ${unitComment}`, positions: unitCommentPlaces }
                        : {
                              comment: undefined,
                              positions: [],
                              problems: []
                          }
                }))
            ]
        }));
        handleCloseToOrderModal();
    }, [
        handleCloseToOrderModal,
        isRetailOrder,
        selectedRows.labors,
        selectedRows.storeGroups,
        unitComment,
        unitCommentPlaces
    ]);

    return (
        <Modal
            destroyOnClose
            footer={null}
            onCancel={() => {
                resetModal();
                setOrderData(undefined);
                setUnitComment(undefined);
                setUnitCommentPlaces([]);
                setSelectedRowKeys({ labors: [], storeGroups: [] });
                setSelectedRows({ labors: [], storeGroups: [] });
            }}
            open={open}
            title={<FormattedMessage id='visual_addition' />}
            width='fit-content'
        >
            <Tabs
                onChange={() => {
                    setUnitComment(undefined);
                    setUnitCommentPlaces([]);
                }}
            >
                <TabPane key='2d' tab='2D'>
                    <Tab2D
                        defaultEmployeeId={defaultEmployeeId}
                        open={open}
                        orderData={orderData}
                        orderId={orderId}
                        reloadOrderForm={reloadOrderForm}
                        resetModal={resetModal}
                        setOrderData={setOrderData}
                        setToOrderData={setToOrderData}
                        toOrderData={toOrderData}
                    />
                </TabPane>
                <TabPane key='3d_body' tab={<FormattedMessage id='visual_addition.3d_body' />}>
                    <Tab3D
                        defaultEmployeeId={defaultEmployeeId}
                        open={open}
                        orderData={orderData}
                        orderId={orderId}
                        reloadOrderForm={reloadOrderForm}
                        resetModal={resetModal}
                        setOrderData={setOrderData}
                        setToOrderData={setToOrderData}
                        setUnitComment={setUnitComment}
                        setUnitCommentPlaces={setUnitCommentPlaces}
                        toOrderData={toOrderData}
                    />
                </TabPane>
                <TabPane key='3d_chassis' tab={<FormattedMessage id='visual_addition.3d_chassis' />}>
                    <Tab3DChassis
                        defaultEmployeeId={defaultEmployeeId}
                        open={open}
                        orderData={orderData}
                        orderId={orderId}
                        reloadOrderForm={reloadOrderForm}
                        resetModal={resetModal}
                        setOrderData={setOrderData}
                        setToOrderData={setToOrderData}
                        setUnitComment={setUnitComment}
                        setUnitCommentPlaces={setUnitCommentPlaces}
                        toOrderData={toOrderData}
                    />
                </TabPane>
            </Tabs>
            <Modal
                okButtonProps={{
                    disabled: !selectedRowKeys.labors.length && !selectedRowKeys.storeGroups.length
                }}
                onCancel={handleCloseToOrderModal}
                onOk={handleConfirmAddModal}
                open={Boolean(orderData)}
                title={formatMessage({ id: 'barcode.add_to_order' })}
                width={768}
            >
                <Flex gap={16}>
                    <Table
                        bordered
                        columns={serviceColumns}
                        dataSource={get(orderData, 'labors', [])}
                        pagination={false}
                        rowKey='laborId'
                        rowSelection={rowSelection('labors')}
                        size='small'
                        style={{
                            width: '50%'
                        }}
                    />
                    <Table
                        bordered
                        columns={detailColumns}
                        dataSource={get(orderData, 'storeGroups', [])}
                        pagination={false}
                        rowKey='storeGroupId'
                        rowSelection={rowSelection('storeGroups')}
                        size='small'
                        style={{
                            width: '50%'
                        }}
                    />
                </Flex>
            </Modal>
        </Modal>
    );
};

const mapStateToProps = state => ({
    user: state.auth
});

export default connect(mapStateToProps)(injectIntl(VisualAdditionModal));
